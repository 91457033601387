import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login/login.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'login',
		component: login
		},{
		path: '/layout',
		name: 'layout',
		component: () => import( /* webpackChunkName: "layout" */ '../views/homePage/layout.vue'),
		redirect:'/homePage',
		children: [{
			path: '/homePage',
			name: 'homePage',
			component: () => import( /* webpackChunkName: "homePage" */ '../views/homePage/homePage.vue')
		},{
			path: '/logisticsCompany',
			name: 'logisticsCompany',
			component: () => import( /* webpackChunkName: "logisticsCompany" */ '../views/logisticsCompany/logisticsCompany.vue')
		},{
			path: '/consignor',
			name: 'consignor',
			component: () => import( /* webpackChunkName: "consignor" */ '../views/consignor/consignor.vue')
		},{
			path: '/logisticsCost',
			name: 'logisticsCost',
			component: () => import( /* webpackChunkName: "logisticsCost" */ '../views/logisticsCost/logisticsCost.vue')
		},{
			path: '/carInfo',
			name: 'carInfo',
			component: () => import( /* webpackChunkName: "carInfo" */ '../views/carInfo/carInfo.vue')
		},{
			path: '/changePassword',
			name: 'changePassword',
			component: () => import( /* webpackChunkName: "changePassword" */ '../views/account/changePassword.vue')
		},{
			path: '/instructions',
			name: 'instructions',
			component: () => import( /* webpackChunkName: "instructions" */ '../views/account/instructions.vue')
		},{
			path: '/ywyeInstructions',
			name: 'ywyeInstructions',
			component: () => import( /* webpackChunkName: "ywyeInstructions" */ '../views/account/ywyeInstructions.vue')
		},{
			path: '/statistics',
			name: 'statistics',
			component: () => import( /* webpackChunkName: "statistics" */ '../views/statistics/statistics.vue')
		},{
			path: '/followUp',
			name: 'followUp',
			component: () => import( /* webpackChunkName: "followUp" */ '../views/followUp/followUp.vue')
		}]
	},
]

const router = new VueRouter({
	routes
})

export default router
