<template>
  <div class="login">
    <div class="login-box">
      <div class="logo">
        <el-image class="logo-img" :src="require('@/assets/img/logo.png')" fit="contain"></el-image>
        <span>智能物流管控平台</span>
      </div>
      <el-form ref="loginForm" :model="form" :rules="rules" class="login-form">
        <h3 class="login-title">欢迎登录</h3>
        <el-form-item prop="username">
          <el-input type="text" placeholder="请输入账号" v-model="form.username"/>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" placeholder="请输入密码" v-model="form.password"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="main-btn" @click="onSubmit('loginForm')">登录</el-button>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>

<script>
import {login, userInfo} from "@/api/user";

export default {
  name: "Login",
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      rules: {
        username: [{
          required: true,
          message: '请输入账号',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }]
      }
    }
  },
  methods: {
    onSubmit(formName) {
      // 为表单绑定验证功能
      this.$refs[formName].validate((valid) => {
        if (valid) {
          login({account: this.form.username, password: this.form.password, isLogisticSystem: 1}).then(res => {
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('name', res.data.name);
            localStorage.setItem('isFirstLogin', res.data.isFirstLogin);
            this.getUserInfo()
          });
        } else {
          return false;
        }
      });
    },
    getUserInfo() {
      userInfo().then(res => {
        localStorage.setItem('powers', res.data.roles.toString());
        this.$router.push("/layout");
      });
    }
  }
}
</script>

<style lang="scss">
//scss
</style>
