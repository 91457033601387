import request from '@/utils/request'

export function login(data) {
    return request({
        url: '/admin/login',
        method: 'post',
        data
    })
}

export function userInfo() {
    return request({
        url: '/admin/info',
        method: 'get'
    })
}

export function updateUserPwd(data) {
  return request({
    url: '/admin/changepwd ',
    method: 'post',
    data
  })
}
