export default {
    today: function () {
        var time = (new Date).getTime();
        var yesterday = new Date(time);
        yesterday = yesterday.getFullYear() + "-" + (yesterday.getMonth() > 8 ? (yesterday.getMonth() + 1) : "0" + (yesterday.getMonth() + 1)) + "-" + (yesterday.getDate() > 9 ? (yesterday.getDate()) : "0" + (yesterday.getDate()));
        return yesterday
    },
    dayOffset: function (date, day) {
        var time = (new Date(date)).getTime() + 24 * 60 * 60 * 1000 * day;
        var yesterday = new Date(time);
        yesterday = yesterday.getFullYear() + "-" + (yesterday.getMonth() > 8 ? (yesterday.getMonth() + 1) : "0" + (yesterday.getMonth() + 1)) + "-" + (yesterday.getDate() > 9 ? (yesterday.getDate()) : "0" + (yesterday.getDate()));
        return yesterday
    },
    yesterday: function () {
        var time = (new Date).getTime() - 24 * 60 * 60 * 1000;
        var yesterday = new Date(time);
        yesterday = yesterday.getFullYear() + "-" + (yesterday.getMonth() > 8 ? (yesterday.getMonth() + 1) : "0" + (yesterday.getMonth() + 1)) + "-" + (yesterday.getDate() > 9 ? (yesterday.getDate()) : "0" + (yesterday.getDate()));
        return yesterday
    },
    lastWeekDay: function () {
        var time = (new Date).getTime() - 7 * 24 * 60 * 60 * 1000;
        var day = new Date(time);
        day = day.getFullYear() + "-" + (day.getMonth() > 8 ? (day.getMonth() + 1) : "0" + (day.getMonth() + 1)) + "-" + (day.getDate() > 9 ? (day.getDate()) : "0" + (day.getDate()));
        return day
    },
    canEdit: function () {
        let powers = localStorage.getItem('powers');
        return powers.split(',').includes('logistics');
    },
    canAudit: function () {
        let powers = localStorage.getItem('powers');
        return powers.split(',').includes('logistics-audit');
    },
    statistic: function () {
        let powers = localStorage.getItem('powers');
        return powers.split(',').includes('logistics-statistic');
    },
    followUp: function () {
        let powers = localStorage.getItem('powers');
        return powers.split(',').includes('logistics-followUp');
    }

}

export function getDate() {
    const date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = month > 9 ? month : '0' + month;
    return `${year}-${month}`;
}
