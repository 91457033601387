import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'font-awesome/css/font-awesome.css'

/* 引入公共js*/
// import common from '@/assets/common.js'
// Vue.prototype.common=common;

//ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

//axios
import axios from 'axios'
Vue.prototype.$axios = axios

import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts

import common from "@/common/common";
Vue.prototype.common = common;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
